@use "~styles/constants" as *;

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    z-index: 3;
    background-color: #072056;
    display: flex;
    justify-content: center;
    padding: 0 5vw;

    &--logo {
        content: $svg_logo_white;
        margin-right: 25px;
        max-width: 150px;
    }

    > div {
        max-width: 1115px;
        width: 100%;
    }
}

.page-header--items {
    padding: 0px 15px;
    width: 100%;
    display: flex;
}

.page-header--menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;
    width: 100%;

    a,
    span {
        cursor: pointer;
        user-select: none;
    }

    &-list {
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        height: 100%;

        &-item {
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 60%;
            white-space: nowrap;
            margin: 0 5px;
            position: relative;

            &:hover {
                background-color: #163c6e;
            }

            &.active {
                background: #14386b;

                span,
                svg {
                    color: white;
                }
            }

            &-button {
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: max-content;
                padding: 12px 20px;

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }

                &.active {
                    background: #14386b;

                    span,
                    svg {
                        color: white;
                    }
                }

                > span {
                    color: #32aacf;
                    font-size: 14px;
                    font-weight: normal;
                }

                > img {
                    width: 25px;
                    height: 25px;
                    margin-right: 8px;
                }
            }
        }
    }
}

.page-header--submenu-list {
    background: #14386b;
    border-radius: 0 5px 5px 5px;
    color: white;

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 93%;
    left: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 999;
    min-width: 160px;

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        &:first-child {
            border-radius: 0 5px 0 0;
        }

        &:last-child {
            border-radius: 0 0 5px 5px;
        }

        &:hover {
            background-color: #32aacf;
        }

        &.active {
            background-color: #32aacf;
        }

        &-link {
            height: 100%;
            text-align: left;
            width: 100%;

            &:hover {
                text-decoration: none;
            }

            &:first-child {
                border-radius: 0 5px 0 0;
            }

            &:last-child {
                border-radius: 0 0 5px 5px;
            }

            > span {
                color: white;
                display: inline-flex;
                font-size: 14px;
                font-weight: normal;
                padding: 12px 50px 12px 20px;
                width: 100%;

                &.active {
                    background-color: #32aacf;

                    &:first-child {
                        border-radius: 0 5px 0 0;
                    }
                }
            }
        }
    }
}
