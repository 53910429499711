.loading {
    &--row,
    &--cell {
        min-height: 150px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &--private-route {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
