@use "~styles/constants" as *;

.container__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    border: solid 1px #e1e2e6;
    background: #ffffff;
    z-index: 10;
    @include responsive(s) {
        height: 60px;
        position: relative;
    }
}

.header {
    display: flex;
    justify-content: flex-start;
    height: 80px;
    @include responsive(s) {
        height: 60px;
    }
}

.header--logo {
    content: $svg_logo;
    display: flex;
    align-items: center;
    max-width: 110px;
    @include responsive(s) {
        width: 80px;
    }
}
