.scd-tax-operation-card {
    width: 90%;
    background: #ffffff;
    border-radius: 8px;
    padding: 22px;
    color: #565a5b;
    border: 1px solid #d8d8d8;

    &--title {
        font-weight: 400;
        font-size: 20px;
    }

    &--item {
        margin: 15px 0px;
        display: flex;
        flex-direction: column;

        &__label {
            font-size: 14px;
            line-height: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            font-size: 17px;
            font-family: 'Frutiger-light';
            line-height: 28px;
        }
    }
}
