.switch-pending-history-button {
    display: flex;
    background-color: #f6f6f6;
    border-radius: 20px;

    &--content {
        background-color: #f6f6f6;
        display: flex;
        font-family: 'Frutiger';
        padding: 20px 30px;
        color: #9199a1;
        width: 100px;
        height: 40px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 20px;

        &.active {
            background-color: white;
            padding: 20px 30px;
            color: #163c6e;
            border: 1px solid #e3e3e3;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            border-radius: 20px;
        }

        &.disabled {
            cursor: default;
        }
    }
}
