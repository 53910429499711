@use '~styles/constants' as *;

.button__style--filter {
    white-space: nowrap;

    .MuiButton-contained {
        border-color: none;

        span {
            color: #163C6E;
        }
        &:hover {
            .button__style--filter-icon {
                content: $svg_plus_hover;
            }
        }
    }

    .MuiButton-label {
        font-size: 13px;
    }

    .MuiButton-contained:hover {
        border-color: none;

        .button--arrow {
            color: $blurple_focus;
        }

        span {
            color: $blurple_focus;
        }
    }

    .MuiIcon-root {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiButton-startIcon {
        display: block;
        height: 100%;
        width: 100%;
    }

    .MuiIcon-root {
        font-size: 1.2rem;
    }

    &-label {
        margin-top: 2px;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        background-color: #F0F0F0;
        box-shadow: unset;
        outline: 0;
        width: 100%;
        max-width: 185px;
        min-width: 10px;
        color: #163C6E;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 45px;
        max-height: 45px;
        padding: 10px 16px;
        border: none;

        @include responsive(ss) {
            max-width: 150px;
            width: 100%;
            margin-bottom: 10px;
        }

        &.Mui-disabled {
            background-color: #F0F0F0;
        }
    }

    .button--arrow {
        color: #163C6E;
    }
}


.button__style--filter-active {
    white-space: nowrap;

    .MuiButton-contained {
        border-color: none;

        span {
            color: #F0F0F0;
        }
        &:hover {
            .button__style--filter-icon {
                content: $svg_plus_hover;
            }
        }
    }

    .MuiButton-label {
        font-size: 13px;
    }

    .MuiButton-contained:hover {
        border-color: none;

        .button--arrow {
            color: #F0F0F0;
        }

        span {
            color: #F0F0F0;
        }
    }

    .MuiIcon-root {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiButton-startIcon {
        display: block;
        height: 100%;
        width: 100%;
    }

    .MuiIcon-root {
        font-size: 1.2rem;
    }

    &-label {
        margin-top: 2px;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        background-color: #163C6E;
        box-shadow: unset;
        outline: 0;
        width: 100%;
        max-width: 165px;
        min-width: 10px;
        color: #F0F0F0;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 45px;
        max-height: 45px;
        padding: 10px 16px;
        border: none;

        @include responsive(ss) {
            max-width: 150px;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .button--arrow {
        color: #163C6E;
    }
}
