@use '~styles/constants' as *;

.operation-approval-modal-filter--container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 420px;

    &-status--label {
        padding: 0px 18px;
        margin: 7px 5px;
        height: 43px;
        border-radius: 17px;
        background-color: #f0f0f0;
        color: #163c6e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Frutiger';
        font-size: 12px;
        letter-spacing: 1.5px;
        cursor: pointer;

        &.DISAPPROVED {
            background-color: #db6968;
            color: white;
        }

        &.PENDING {
            background-color: #f2c466;
        }

        &.APPROVED {
            background-color: #6acff1;
        }
    }

    &-search-content {
        &.active {
            height: 120px;
            border: 1px solid #3f41d1;
            border-radius: 7px;
        }
    }
}

.operation-approval-modal-filter--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    &-title {
        font-size: 20px;
        font-weight: bold;
        color: #292a2a;
        letter-spacing: 0.3px;
        line-height: 1.2;
        margin: 0;
    }
}

.operation-approval-modal-filter--buttons {
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }
}

.operation-approval-modal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgba(0, 0, 0, 0.2);
    padding-bottom: 65px;
}
