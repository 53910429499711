.scd-loan-operation-card {
    width: 90%;
    background: #3f578b;
    border-radius: 8px;
    padding: 22px;
    color: #ffffff;
    margin-bottom: 23px;

    &--title {
        font-weight: 400;
        font-size: 19px;
    }

    &--item {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        &__label {
            font-size: 14px;
            font-family: 'Frutiger-light';
            line-height: 14px;
        }
    
        &__value {
            font-size: 17px;
            font-family: 'Frutiger-light';
            line-height: 28px;
        }
    
        &__total-value {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
        }
    }
    
}
