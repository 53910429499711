@use '~styles/constants' as *;

.scd-account-balance {
    &__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
}
