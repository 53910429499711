@use '~styles/constants' as *;

.payment-transaction-modal-details {
    z-index: 2;
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include responsive(s) {
        width: 450px;
    }

    @include responsive(ss) {
        width: 350px;
    }

    @media print {
        width: 100%;
        height: 100%;
        padding-top: 30%;
    }

    &--header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-right: 25px;

        @media print {
            display: none;
        }

        &-close-button {
            .MuiIconButton-root {
                padding: 35px 10px 10px 0;
                outline: none;
                &:hover {
                    background: none;
                    &-close-button-icon {
                        content: $svg_close_hover;
                    }
                }
            }
            .MuiIcon-root {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
            }
            &-icon {
                content: $svg_close_rest;
                height: 30px;
                width: 30px;

            }
        }
    }

    &--title {
        font-size: 32px;
        font-weight: 700;
        line-height: 1.25;
        text-align: center;
        color: #4a4a4a;
        padding: 0 20px;
        font-family: 'Frutiger-55';

        @media print {
            text-align: center !important;
            padding-right: 20px;
            font-size: 60px;
        }
    }

    &--content {
        width: 65%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 6px;
        background: #f5f5f5;
        padding: 10px 20px;

        @media print {
            width: 75%;
            padding: 60px 0 20px 22px;
            background: #ffff;
        }

        &-message {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 5px 0;

            &--subtitle {
                font-family: 'Frutiger-55';
                font-size: 14px;
                color: $cool_blue_two;

                @media print {
                    font-size: 35px;
                }
            }
            &--info {
                font-family: 'Frutiger-light';
                font-weight: 200;
                font-size: 15px;

                @media print {
                    font-size: 38px;
                }
            }
        }
    }
    &--text {
        width: 100%;
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        color: #545454;
        padding: 0 20px;
        font-family: 'Frutiger-light';
    }
    &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        white-space: pre-line;

        > :nth-child(2) {
            margin-left: 25px;
        }
    }
    &--download-button {
        display: flex;
        cursor: pointer;
        padding: 30px 0;

        &__icon {
            content: $svg_download_active;
        }

        &__value {
            letter-spacing: 1.5px;
            color: #3f41d1;
            font-weight: 400;
            font-size: 15px;
            margin: 0px 8px;
            line-height: 24px;
        }
        @media print {
            display: none;
        }
    }

    &--icon {
        text-align: center;
        padding-bottom: 15px;
    }
}

.modal-content {
    bottom: 50px;
}
