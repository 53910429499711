@use "~styles/constants" as *;

.container__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    border: solid 1px $light-periwinkle;
    background: #ffffff;
    z-index: 1;
}

.footer--text {
    display: flex;
    align-items: center;
    justify-content: center;
    // font-family: 'Raleway';
    font-size: 0.875rem;
    font-weight: 500;
    color: #9b9b9b;
    height: 50px;
    text-align: center;
    width: 100%;
}
