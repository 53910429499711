@use "~styles/constants" as *;

.login-container {
    height: 100vh;
}

.login--title {
    color: $greyish_brown;
    font-size: 0.875rem;
    font-family: 'Frutiger';
    font-weight: 500;

    p {
        margin: 0px;
    }
}

.login--link {
    text-decoration: underline;
    font-size: 0.8125rem;
    font-family: 'Frutiger';
    text-align: right;

    a {
        color: #707070;
    }
}

.login--logo {
    content: $svg_logo_white;
    position: absolute;
    top: 40px;
    left: 50px;
    max-width: 150px;
    z-index: 1;

    @include responsive(sm) {
        display: none;
    }
    
    @include responsive(s) {
        display: none;
    }
}

.login--form-container {
    width: 50%;

    @include responsive(s) {
        width: 100%;
    }
}

.login--form-logo {
    display: flex;
    justify-content: center;
    padding: 20px 20px 50px;
    width: 100%;
    &-image {
        width: 150px;
        content: $svg_logo;
    }
}

.login--row {
    place-content: center;
    margin: auto;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include responsive(s) {
        padding: 0px 25px;
    }
}

.login--column {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
