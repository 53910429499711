.page-container--pagination {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;

    &-content {
        display: flex;
    }
    &-icon,
    &-icon-disabled {
        background: none;
        border-radius: 50%;
        color: #7b7b7b;
        width: 35px;
        font-family: 'Frutiger';
        font-weight: 700;
        display: flex;
        height: 35px;
        align-items: center;
        justify-content: center;
        outline: 0;
    }

    &-icon {
        &:hover {
            background: #3f42d1;
            color: white;
            cursor: pointer;
            outline: 0;
        }

        .MuiSvgIcon-root {
            font-size: 1.1rem;
        }
    }

    &-icon-disabled {
        a {
            cursor: alias;
        }

        .MuiSvgIcon-root {
            font-size: 1.1rem;
            cursor: alias;
        }
    }

    &-item {
        background: none;
        border-radius: 50%;
        font-size: 12px;
        color: #7b7b7b;
        width: 35px;
        font-family: 'Frutiger';
        font-weight: 700;
        display: flex;
        height: 35px;
        align-items: center;
        justify-content: center;
        outline: 0;

        a {
            width: 100%;
            border-radius: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-active {
            background: #3f42d1;
            color: white;
        }

        &:hover {
            background: #3f42d1;
            color: white;
            cursor: pointer;
            outline: 0;
        }
    }
}
