@use '~styles/constants' as *;

* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:after,
    &::before {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

body {
    margin: 0;
    font-family: 'Frutiger';
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
label {
    margin: 0;
    font-family: 'Frutiger';
}

a {
    text-decoration: none;
}

input,
input:focus,
button:focus {
    outline: none;
}

input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
    display: none;
}

input:-webkit-autofill {
    border: none !important;
    background-color: transparent !important;
    box-shadow: 0 transparent inset;
    -webkit-box-shadow: 0 transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

// Global View

.page {
  &__container {
      height: 100vh;
      position: relative;
      background-color: #FAFAFA;
      padding-top: 98px;
  }
  &__content {
      height: calc(100% - 46px);
      overflow-y: auto;
      overflow-x: hidden;

      @include responsive(ss) {
          margin-top: 100px;
      }

      > div {
          height: 100%;
          max-height: calc(100vh - 125.6px);
      }

      &--public {
          height: 100vh;
          width: 100vw;
      }
  }

  &__global-error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 90vh;
      padding-bottom: 120px;

      .error-message {
          font-family: 'Catamaran', sans-serif;
          font-size: 1.25rem;
          color: $scorpion;
          text-align: center;
          line-height: 20px;
          margin-bottom: 30px;
      }
      .button__style--back .MuiButton-contained:hover,
      .button__style--back .MuiButton-contained {
          max-width: 220px;
      }
  }
}

// Modal Bootstrap
.modal-dialog {
  max-width: 800px !important;
}
