@use '~styles/constants' as *;

.scd-payment-accounts-balance {
    max-height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;

    &__container {
        max-width: 1095px;
        min-height: 450px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;

        &--title {
            font-size: 27px;
            font-weight: 500;
            color: #545454;
            letter-spacing: 0.5px;
            margin: 0 0 9px 0;
            display: inline-block;
        }

        &--title-error {
            height: 70vh;
            align-items:center;
            display: flex;
            opacity: 0.6;
            color: gray;
        }

    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        row-gap: 20px;
        position: relative;
        min-height: 500px;
    }

    
}
