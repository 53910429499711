@use '~styles/constants' as *;

.scd-header-imported-operation-card {
    width: 100%;
    height: 65px;
    background: #ffffff;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    padding-left: 25px;
    overflow-x: scroll;

    @include responsive(s) {
        width: 100vw;
        padding: 0 30px 0 25px;
    }
    @include responsive(s) {
        width: 100vw;
        padding: 0 30px 0 25px;
    }

    @include responsive(s) {
        width: 100vw;
        padding: 0 30px 0 25px;
    }

    &--item {
        display: flex;
        width: 15%;
        min-width: 150px;
        flex-direction: column;
        white-space: nowrap;

        &__label {
            font-size: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            color: #343434;
            font-size: 15px;
            line-height: 19px;
            font-family: 'Frutiger-light';
            width: 90%;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        &__status {
            display: flex;
            font-size: 11px;
            align-items: center;
            white-space: nowrap;
            justify-content: center;
            height: 20px;
            width: 80px;
            font-weight: 500;
            border-radius: 5px;

            &.CONFIRMED {
                background-color: #b8d759;
            }

            &.ERROR {
                background-color: #db6968;
                color: #ffffff;
            }
            &.CANCELLED {
                background-color: #db6968;
                color: #ffffff;
            }
            &.UNRESOLVED {
                background-color: #da8d8b;
                color: #ffffff;
            }
            &.WAITING {
                background-color: #6acff1;
            }
        }
    }

    &--item-favored {
        display: flex;
        width: 25%;
        min-width: 200px;
        flex-direction: column;
        white-space: nowrap;

        &__label {
            font-size: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            color: #343434;
            font-size: 15px;
            line-height: 19px;
            font-family: 'Frutiger-light';
        }
    }

    &--item-status {
        display: flex;
        width: 15%;
        flex-direction: column;
        white-space: nowrap;

        &__label {
            font-size: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            color: #343434;
            font-size: 15px;
            line-height: 19px;
            font-family: 'Frutiger-light';
            width: 90%;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }

    &--item-more-options {
        display: flex;
        width: 5%;
        flex-direction: column;
        white-space: nowrap;
    }
}
