@use '~styles/constants' as *;

.scd-ccb-operation-detail {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        width: 100%;
        margin-bottom: 40px;
        &--title {
            font-size: 26px;
            font-weight: 500;
            color: #565a5b;
            margin: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        &--status {
            display: inline-flex;
            font-size: 12px;
            align-items: center;
            white-space: nowrap;
            justify-content: flex-start;
            height: 24px;
            font-weight: 500;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 15px;

            &.ERROR {
                background-color: #db6968;
                color: white;
                width: 55px;
            }

            &.RECEIVED {
                background-color: #f2c466;
                width: 90px;
            }

            &.PROCESSED {
                background-color: #6acff1;
                width: 90px;
            }

            &.ARCHIVED {
                color: #ffffff;
                background-color: #2c5d6c;
                width: 95px;
            }

            &.DISAPPROVED {
                background-color: #494747;
                color: white;
                width: 90px;
            }
        }

        &--info {
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #9199a1;
            margin-bottom: 45px;

            span {
                &:nth-child(1) {
                    padding-right: 20px;
                }
            }
        }
    }
}
