.scd-header-operation-info-card {
    width: 555px;
    height: 65px;
    background: #ffffff;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    padding-left: 25px;
    &--item {
        display: flex;
        flex-direction: column;
        width: 210px;
        margin-right: 245px;

        &__label {
            font-size: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            color: #343434;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Frutiger-light';
        }
    }
}

.scd-header-operation-installment-card {
    width: 150px;
    height: 65px;
    background: #ffffff;
    border: 0.5px solid rgba(63, 87, 139, 0.5);
    box-shadow: 5px 1px 5px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0px 6px 6px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 25px;
    cursor: pointer;

    &-ref {
        position: relative;
    }
    &.active {
        background: #929eb8;
        color: #ffffff;
    }

    &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &__label {
            font-size: 14px;
            color: #3f578b;
            font-family: 'Frutiger-light';

            &.active {
                color: #ffffff;
                font-weight: 400;
                font-family: 'Frutiger';
            }
        }

        &__value {
            color: #3f578b;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Frutiger-light';

            &.active {
                color: #ffffff;
                font-weight: 400;
                font-family: 'Frutiger';
            }
        }
    }
}

.scd-header-operation-expiration-card {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 152px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px 23px;

    &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 15px;

        &__label {
            font-size: 13px;
            color: #565a5b;
            font-family: 'Frutiger-light';
        }

        &__value {
            color: #343434;
            font-size: 16px;
            line-height: 25px;
            font-family: 'Frutiger';
        }
    }

    &--expiration-number {
        text-align: center;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__value {
            color: #343434;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            input {
                width: 40px;
                border: none;
                text-align: center;
                color: #343434;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
            }

            input:focus {
                font-family: 'Frutiger-light';
                background-color: #f0f0f0;
                font-weight: 300;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        &__icon {
            color: #565a5b;
            font-size: 15px;
            width: 30px;
            cursor: pointer;
        }
    }
}
