@use '~styles/constants' as *;

.button-batch__style--more-options-icon {
    .MuiButton-contained,
    .MuiButton-contained:hover {
        background: none;
        box-shadow: unset;
        border: none;
        outline: none;
        min-width: 20px;
        max-width: 100%;
        &.Mui-disabled {
            .button__style--more-options-icon {
                content: $svg_more_options_disabled;
            }
        }
    }

    .MuiButton-label {
        width: auto;
    }

    &--menu {
        width: 30px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-left: 1px solid #fff !important;

        &.active {
            background-color: $organization_primary_focus !important;
        }

        &-menulist {
            .MuiMenu-paper {
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
            }
            &-item {
                font-size: 14px !important;
            }
        }
    }

    &--icon {
        content: $svg_more_options_rest;
        height: 18px;
        width: 20px;
        &:hover {
            content: $svg_more_options_hover;
        }
    }
    &--items {
        color: darkblue !important;
    }
}
