@use "~styles/constants" as *;
@import url('https://fonts.googleapis.com/css2?family=Catamaran&display=swap');

.error-toast__itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 215px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(189, 189, 189, 0.5);
    border: solid 1px #ececec;
    padding: 30px 23px 37px 23px;

    &--img {
        content: $svg_ops-2;
        margin-bottom: 17px;
    }

    &--text {
        font-family: 'Catamaran', sans-serif;
        font-size: 0.875rem;
        color: #5e5e5e;
        text-align: center;
        line-height: 20px;
        margin-bottom: 15px;
    }

    &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #c6c6c6;
        border-radius: 10px;
        width: 150px;
        height: 40px;
        background: white;
        color: $brown_grey_three;
        font-family: frutiger;
        font-size: 0.875rem;
        padding: 12px;
        cursor: pointer;
    }

    &--button:hover {
        border: solid 1px $brown_grey;
        color: $brown_grey;
    }

    &--close {
        content: $svg_fechar-3;
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
    }
}
