@use '~styles/constants' as *;

.number__input {
    .MuiInputAdornment-positionEnd {
        position: absolute;
        right: 0;
        top: 0;
        max-height: inherit;

        .MuiIconButton-root {
            padding: 0 5px;
            border: solid 1px #aaaaaa;
            background-color: #ffffff;
            height: 21px;
            outline: 0;

            &:first-child {
                border-bottom: 0;
                margin-top: 3px;
                margin-right: 3px;
                border-radius: 5px 5px 0 0;
            }
            &:last-child {
                border-radius: 0 0 5px 5px;
                margin-bottom: 3px;
                margin-right: 3px;
            }
        }
    }
    &--arrow-up-icon {
        content: $svg_arrow;
        transform: rotate(180deg);
    }
    &--arrow-down-icon {
        content: $svg_arrow;
    }
}
