@use '~styles/constants' as *;

.body__page-not-found {
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';

    .page-not-found--itens {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 130px;
        @include responsive(s) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .page-not-found--image {
            content: $svg_ops;
            margin-bottom: 30px;
            width: 200px;
            @include responsive(s) {
                width: 100px;
            }
        }

        .page-not-found--text {
            text-align: center;
            font-family: Frutiger;
            font-size: 1.125rem;
            color: $greyish_brown;
            font-weight: 300;

            span {
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 13px;
            }

            .page-not-found--subtitle {
                margin-top: 20px;
            }
        }
    }
}
