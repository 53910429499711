@use '~styles/constants' as *;

.scd-payment-transaction {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            &-refresh-icon {
                color: #3f41d1;
                cursor: pointer;
                margin-left: 10px;
            }
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }

            &-new-transaction {
                width: 120px;
                margin: 0px 10px;
            }

            &-in-series {
                width: 140px;
                margin: 0px 10px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 16%;
                }
                &:nth-child(2) {
                    width: 11%;
                }
                &:nth-child(3) {
                    width: 30%;
                }
                &:nth-child(4) {
                    width: 12%;
                }
                &:nth-child(5) {
                    width: 18%;
                }
                &:nth-child(6) {
                    width: 18%;
                }
            }
            td {
                overflow: hidden;
                text-overflow: ellipsis;
                &:nth-child(1) {
                    width: 16%;
                }
                &:nth-child(2) {
                    width: 11%;
                }
                &:nth-child(3) {
                    width: 30%;
                }
                &:nth-child(4) {
                    width: 12%;
                }
                &:nth-child(5) {
                    width: 18%;
                }
                &:nth-child(6) {
                    width: 14%;
                }
                &:nth-child(7) {
                    width: 5%;
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 190px;
                    }
                    &:nth-child(2) {
                        width: 140px;
                    }
                    &:nth-child(3) {
                        width: 410px;
                    }
                    &:nth-child(4) {
                        width: 140px;
                    }
                    &:nth-child(5) {
                        width: 230px;
                    }
                    &:nth-child(6) {
                        width: 130px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 190px;
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                    &:nth-child(3) {
                        width: 400px;
                    }
                    &:nth-child(4) {
                        width: 140px;
                    }
                    &:nth-child(5) {
                        width: 230px;
                    }
                    &:nth-child(6) {
                        width: 130px;
                    }
                }
            }

            &-status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 25px;
                font-weight: 500;
                border-radius: 5px;

                &.CONFIRMED {
                    background-color: #b8d759;
                }

                &.ERROR {
                    background-color: #db6968;
                    color: #ffffff;
                }
                &.CANCELLED {
                    background-color: #db6968;
                    color: #ffffff;
                }
                &.UNRESOLVED {
                    background-color: #da8d8b;
                    color: #ffffff;
                }
                &.WAITING {
                    background-color: #6acff1;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-fields {
            display: flex;

            &-table-column {
                &__amount-value {
                    width: 140px;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-items: center;
                }

                &__favored-value {
                    width: 320px;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-items: center;
                }

                &__agency-value {
                    width: 140px;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-items: center;
                }
                &__account-value {
                    width: 174px;
                    margin-left: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 16px;
                    align-items: center;
                }
            }
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
