@use '~styles/constants' as *;

.content__modal-forgot-password--itens {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content__modal-forgot-password--image {
        content: url('../../../../images/paper-plane.svg');
        margin: auto;
    }
    .content__modal-forgot-password--title {
        font-family: Frutiger;
        font-size: 1.3rem;
        font-weight: 400;
        color: $greyish_brown;
        margin-top: 8vh;
        margin-bottom: 3vh;
    }

    .content__modal-forgot-password--message {
        font-family: Frutiger;
        font-size: 1.1rem;
        font-weight: 300;
        color: $greyish_brown;
        margin-bottom: 7vh;
    }
}
