@use "~styles/constants" as *;

.register__finish {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding-top: 120px;

    h2 {
        font-size: 1rem;
    }

    &--container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 360px;
        margin: 0 auto;
    }

    &--content {
        width: 100%;
    }

    &--header {
        padding: 50px 0 20px;
        width: 100%;

        &--title {
            margin: auto;
            font-size: 1.375rem;
            text-align: center;
            margin-bottom: 25px;
            line-height: 1.3;
            @include responsive(s) {
                margin-bottom: 15px;
            }

            span {
                font-weight: 500;
            }
        }
    }

    &--field {
        margin-bottom: 25px;
        width: 100%;
    }

    &--button {
        display: flex;
        justify-content: center;
        width: 100%;

        .button__style--standard {
            display: flex;
            justify-content: flex-end;
        }
        .button__style--standard .MuiButton-contained:hover,
        .button__style--standard .MuiButton-contained {
            max-width: 90%;
        }
    }
}
