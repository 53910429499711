.scd-base-operation-card {
    width: 85%;
    background: #ffffff;
    border-radius: 8px;
    padding: 13px 20px;
    color: #565a5b;
    border: 1px solid #d8d8d8;
    margin-bottom: 24px;

    &--title {
        font-weight: 400;
        font-size: 19px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;

        &__icon {
            cursor: pointer;
        }
    }

    &--item {
        margin: 15px 0px;
        display: flex;
        flex-direction: column;

        &__label {
            font-size: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            font-size: 17px;
            font-family: 'Frutiger-light';
            line-height: 28px;
        }
    }
}
