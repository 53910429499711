@use '~styles/constants' as *;

.scd-batch {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            width: 100%;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }

            &--refresh-icon {
                color: #3f41d1;
                cursor: pointer;
                margin-left: 10px;
                margin-top: 8px;
            }
        }

        &--filter-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 15%;
                }
                &:nth-child(2) {
                    width: 15%;
                }
                &:nth-child(3) {
                    width: 23%;
                }
                &:nth-child(4) {
                    width: 22%;
                }
                &:nth-child(5) {
                    width: 15%;
                }
                &:nth-child(6) {
                    width: 15%;
                }
            }
            td {
                margin-right: 11px;

                &:nth-child(1) {
                    width: 13.25%;
                }

                &:nth-child(2) {
                    width: 13.25%;

                    span {
                        color: #163c6e;
                        border: 1px solid #163c6e;
                        height: 25px;
                        width: 40px;
                        border-radius: 4px;
                    }
                }

                &:nth-child(3) {
                    width: 21%;
                }
                &:nth-child(4) {
                    width: 20%;
                }
                &:nth-child(5) {
                    width: 13.5%;
                }
                &:nth-child(6) {
                    width: 8%;
                }
                &:nth-child(7) {
                    width: 3%;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 16%;
                    }
                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                    &:nth-child(4) {
                        width: 19%;
                    }
                    &:nth-child(5) {
                        width: 17.5%;
                    }
                    &:nth-child(6) {
                        width: 15%;
                    }
                }
                td {
                    margin-right: 11px;

                    &:nth-child(1) {
                        width: 14.75%;
                    }
                    &:nth-child(2) {
                        width: 9%;
                    }
                    &:nth-child(3) {
                        width: 14%;
                    }
                    &:nth-child(4) {
                        width: 17.5%;
                    }
                    &:nth-child(5) {
                        width: 15.5%;
                    }
                    &:nth-child(6) {
                        width: 15%;
                    }
                    &:nth-child(7) {
                        width: 5%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 150px;
                    }
                    &:nth-child(2) {
                        width: 125px;
                    }
                    &:nth-child(3) {
                        width: 150px;
                    }
                    &:nth-child(4) {
                        width: 150px;
                    }
                    &:nth-child(5) {
                        width: 125px;
                    }
                    &:nth-child(6) {
                        width: 125px;
                    }
                }
                td {
                    margin-right: 11px;

                    &:nth-child(1) {
                        width: 139px;
                    }
                    &:nth-child(2) {
                        width: 114px;
                    }
                    &:nth-child(3) {
                        width: 139px;
                    }
                    &:nth-child(4) {
                        width: 139px;
                    }
                    &:nth-child(5) {
                        width: 114px;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 5%;
                    }
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 1px 10px;

                &.RECEIVED {
                    background-color: #f2c466;
                }

                &.CLOSED {
                    color: #ffffff;
                    background-color: #2c5d6c;
                }

                &.PROCESSED {
                    background-color: #6acff1;
                }

                &.ERROR {
                    background-color: #db6968;
                    color: white;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
