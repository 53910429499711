@use '~styles/constants' as *;

.scd-batch-imported-operations {
    max-height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;

    &__container {
        max-width: 1095px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;

        &--title {
            font-size: 27px;
            font-weight: 500;
            color: #545454;
            letter-spacing: 0.5px;
            margin: 0 0 9px 0;
            display: inline-block;
        }

        &--info {
            font-family: 'Frutiger-55';
            font-size: 19px;
            font-weight: 400;
            color: #bac0c2;
            letter-spacing: 1.2px;
            margin-top: 12px;
        }
    }

    &__content {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-header {
            display: flex;

            &--title {
                font-family: 'Frutiger-55';
                font-size: 23px;
                font-weight: 500;
                letter-spacing: 1.2px;
                margin: 0;
                margin-right: 9px;
            }

            &--total-itens {
                font-family: 'Frutiger-55';
                color: #343434;
                border: 1px solid #343434;
                padding: 10px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 28px;
                font-size: 14px;
            }
        }

        &-table {
            margin-top: 12px;
            width: 100%;
            overflow-x: auto;

            .page-container--table {
                th {
                    &:nth-child(1) {
                        width: 15%;
                    }
                    &:nth-child(2) {
                        width: 13%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 40%;
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                }
                td {
                    margin-right: 10px;

                    &:nth-child(1) {
                        width: 14%;
                    }
                    &:nth-child(2) {
                        width: 12.3%;
                    }
                    &:nth-child(3) {
                        width: 9.2%;

                        span {
                            border: 1px solid #163c6e;
                            color: #163c6e;
                            border-radius: 4px;
                            width: 40px;
                            height: 28px;
                            padding: 5px 8px;
                        }
                    }
                    &:nth-child(4) {
                        width: 39%;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                            font-family: 'Frutiger-light';
                        }
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                }

                @include responsive(s) {
                    th {
                        &:nth-child(1) {
                            width: 150px;
                        }
                        &:nth-child(2) {
                            width: 150px;
                        }
                        &:nth-child(3) {
                            width: 130px;
                        }
                        &:nth-child(4) {
                            width: 110px;
                        }
                        &:nth-child(5) {
                            width: 360px;
                        }
                        &:nth-child(6) {
                            width: 130px;
                        }
                    }
                    td {
                        &:nth-child(1) {
                            width: 140px;
                        }
                        &:nth-child(2) {
                            width: 140px;
                        }
                        &:nth-child(3) {
                            width: 120px;
                        }
                        &:nth-child(4) {
                            width: 105px;
                        }
                        &:nth-child(5) {
                            width: 350px;
                        }
                        &:nth-child(6) {
                            width: 130px;
                        }
                    }
                }
            }
        }
    }
}
