@use '~styles/constants' as *;

.redirect__style--more-options {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }

    li {
        font-family: 'Frutiger-light' !important;
        font-size: 14px !important;
        color: #072056 !important;
        &:hover {
            font-size: 14px !important;
            font-weight: 800;
        }  
    }
}