@use "~styles/constants" as *;

.carousel--color {
    &,
    .carousel-inner,
    .carousel-item,
    .carousel.slide {
        height: 100%;
    }

    .carousel--item {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .carousel-caption {
        bottom: 127px;

        p {
            font-size: 1.75rem;
            font-family: 'Frutiger';
            font-weight: 500;
            max-width: 382px;
            margin: auto;
            color: $piss_yellow;
            margin-top: -3px;
        }

        h3 {
            font-size: 1.75rem;
            font-family: 'Frutiger';
            font-weight: 500;
            margin: auto;
        }
    }

    .carousel-item:nth-child(1) {
        h3 {
            max-width: 411px;
        }
    }

    .carousel-item:nth-child(2) {
        h3 {
            max-width: 410px;
        }
    }

    .carousel-item:nth-child(3) {
        h3 {
            max-width: 447px;
        }
    }

    .carousel-indicators {
        bottom: 90px;
        align-items: center;

        li {
            width: 6px;
            height: 6px;
            margin-right: 4px;
            margin-left: 4px;
            border-radius: 3px;
            border: unset;
            background: #a4a9b4;
            cursor: pointer;
        }

        .active {
            background: $piss_yellow;
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }
    }
}
