@use '~styles/constants' as *;

.context-ribbon {
    background-color: #fafafa;
    height: 45px;
    border-bottom: solid 1px $very_light_pink_two;
    position: fixed;
    background: $white_two;
    top: 74px;
    left: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
    padding: 0 5vw;
    display: flex;
    justify-content: center;

    .container {
        padding: 0;
        margin: 0;
        max-width: 1115px;
        width: 100%;
    }

    &-row {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }

    &-child {
        display: flex;
        justify-content: flex-end;
    }

    .context-ribbon__back {
        display: flex;
        align-items: center;
        height: 45px;
        line-height: 45px;

        .context-ribbon__back-icon {
            content: $svg_back_simulation;
            margin-right: 10px;
            cursor: pointer;
        }

        span {
            font-size: 0.875rem;
            font-family: Arial, Helvetica, sans-serif;
            letter-spacing: 0.3px;
            color: $cool_blue;
            cursor: pointer;
            margin-top: 1.45px;
        }
    }
}
