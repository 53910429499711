$svg_ops: url('~images/ops.svg');
$svg_ops-2: url('~images/ops-2.svg');

$svg_fechar-3: url('~images/fechar-3.svg');

$svg_error: url('~images/error.svg');

$svg_logo: url('~images/logo.svg');
$svg_logo_white: url('~images/logo-white.svg');

$svg_paper_plane: url('~images/paper-plane.svg');

$svg_positivo: url('~images/positivo.svg');

$svg_edit_disabled: url('~images/edit-disabled.svg');
$svg_edit_rest: url('~images/edit-rest.svg');
$svg_edit_hover: url('~images/edit-hover.svg');

$svg_visualize_disabled: url('~images/visualize-disabled.svg');
$svg_visualize_rest: url('~images/visualize-rest.svg');
$svg_visualize_hover: url('~images/visualize-hover.svg');

$svg_trash_disabled: url('~images/trash-disabled.svg');
$svg_trash_rest: url('~images/trash-rest.svg');
$svg_trash_hover: url('~images/trash-hover.svg');

$svg_close_hover: url('~images/close-hover.svg');
$svg_close_rest: url('~images/close-rest.svg');

$svg_back_simulation: url('~images/back-simulation.svg');

$svg_blue_search: url('~images/blue-search.svg');

$svg_arrow: url('~images/arrow.svg');

$svg_search: url('~images/search.svg');

$svg_profile: url('~images/profile.svg');

$svg_calendar: url('~images/calendar.svg');
$svg_calendar_rest: url('~images/calendar-rest.svg');

$svg_error_arrow: url('~images/error-arrow.svg');

$svg_piggy_bank: url('~images/piggy-bank.svg');

$svg_description: url('~images/description.svg');
$svg_description_active: url('~images/description-active.svg');

$svg_plus_disabled: url('~images/plus-disabled.svg');
$svg_plus_rest: url('~images/plus-rest.svg');
$svg_plus_hover: url('~images/plus-hover.svg');

$svg_download_active: url('~images/download-active.svg');

$svg_more_options_disabled: url('~images/more-options-disabled.svg');
$svg_more_options_rest: url('~images/more-options-rest.svg');
$svg_more_options_hover: url('~images/more-options-hover.svg');
$svg_select: url('~images/select.svg');
