@use '~styles/constants' as *;

.scd-ccb-operation-approval {
    height: 700px;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            width: 100%;

            &__icon-container {
                display: flex;
                align-items: center;
                width: 100%;
            }

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                justify-content: flex-end;
                display: flex;
                align-items: center;
                margin-left: 30px;
            }

            &--refresh-icon {
                color: #3f41d1;
                cursor: pointer;
                margin-left: 10px;
                margin-top: 8px;
            }
        }

        &--filter-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        &--select-operations {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            &__icon {
                content: $svg_select;
            }

            &__value {
                letter-spacing: 1.5px;
                color: #3f41d1;
                font-weight: 400;
                font-size: 15px;
                margin: 0px 8px;
                line-height: 24px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            &.PENDING {
                th {
                    &:nth-child(1) {
                        width: 25%;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 45%;
                    }
                }
                td {
                    margin-right: 11px;

                    &:nth-child(1) {
                        width: calc(25% - 11px);
                    }
                    &:nth-child(2) {
                        width: calc(25% - 11px);

                        span {
                            font-family: 'Frutiger-light';
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 2.43;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }
                    }
                    &:nth-child(3) {
                        width: calc(45% - 11px);

                        span {
                            font-family: 'Frutiger-light';
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 2.43;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }
                    }
                    &:nth-child(4) {
                        width: 3%;
                    }
                }

                @include responsive(sm) {
                    th {
                        &:nth-child(1) {
                            width: 25%;
                        }
                        &:nth-child(2) {
                            width: 25%;
                        }
                        &:nth-child(3) {
                            width: 45%;
                        }
                    }
                    td {
                        margin-right: 11px;

                        &:nth-child(1) {
                            width: calc(25% - 11px);
                        }
                        &:nth-child(2) {
                            width: calc(25% - 11px);
                        }
                        &:nth-child(3) {
                            width: calc(45% - 11px);
                        }
                        &:nth-child(4) {
                            width: 4%;
                        }
                    }
                }

                @include responsive(s) {
                    th {
                        &:nth-child(1) {
                            width: 200px;
                        }
                        &:nth-child(2) {
                            width: 200px;
                        }
                        &:nth-child(3) {
                            width: 200px;
                        }
                    }
                    td {
                        margin-right: 11px;

                        &:nth-child(1) {
                            width: 190px;
                        }
                        &:nth-child(2) {
                            width: 190px;
                        }
                        &:nth-child(3) {
                            width: 185px;
                        }
                        &:nth-child(4) {
                            width: 5%;
                        }
                    }
                }
            }
            &.OTHERS {
                th {
                    &:nth-child(1) {
                        width: 15%;
                    }
                    &:nth-child(2) {
                        width: 17%;
                    }
                    &:nth-child(3) {
                        width: 25.5%;
                    }
                    &:nth-child(4) {
                        width: 18%;
                    }
                    &:nth-child(5) {
                        width: 14%;
                    }
                    &:nth-child(6) {
                        width: 13%;
                    }
                }
                td {
                    margin-right: 11px;

                    &:nth-child(1) {
                        width: 13.5%;
                    }
                    &:nth-child(2) {
                        width: 15.3%;

                        span {
                            font-family: 'Frutiger-light';
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 2.43;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }
                    }
                    &:nth-child(3) {
                        width: 24%;

                        span {
                            font-family: 'Frutiger-light';
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 2.43;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }
                    }
                    &:nth-child(4) {
                        width: 17%;

                        span {
                            font-family: 'Frutiger-light';
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 2.43;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }
                    }
                    &:nth-child(5) {
                        width: 12.3%;

                        span {
                            font-family: 'Frutiger-light';
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 2.43;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }
                    }
                    &:nth-child(6) {
                        width: 8%;
                    }
                    &:nth-child(7) {
                        width: 3%;
                    }
                }

                @include responsive(sm) {
                    th {
                        &:nth-child(1) {
                            width: 18%;
                        }
                        &:nth-child(2) {
                            width: 20%;
                        }
                        &:nth-child(3) {
                            width: 15%;
                        }
                        &:nth-child(4) {
                            width: 20%;
                        }
                        &:nth-child(5) {
                            width: 13%;
                        }
                        &:nth-child(6) {
                            width: 10%;
                        }
                    }
                    td {
                        margin-right: 11px;

                        &:nth-child(1) {
                            width: 17.2%;
                        }
                        &:nth-child(2) {
                            width: 18.2%;
                        }
                        &:nth-child(3) {
                            width: 14.5%;
                        }
                        &:nth-child(4) {
                            width: 19%;
                        }
                        &:nth-child(5) {
                            width: 11%;
                        }
                        &:nth-child(6) {
                            width: 9%;
                        }
                        &:nth-child(7) {
                            width: 4%;
                        }
                    }
                }

                @include responsive(s) {
                    th {
                        &:nth-child(1) {
                            width: 200px;
                        }
                        &:nth-child(2) {
                            width: 200px;
                        }
                        &:nth-child(3) {
                            width: 200px;
                        }
                        &:nth-child(4) {
                            width: 200px;
                        }
                        &:nth-child(5) {
                            width: 200px;
                        }
                        &:nth-child(6) {
                            width: 200px;
                        }
                    }
                    td {
                        margin-right: 11px;

                        &:nth-child(1) {
                            width: 190px;
                        }
                        &:nth-child(2) {
                            width: 190px;
                        }
                        &:nth-child(3) {
                            width: 185px;
                        }
                        &:nth-child(4) {
                            width: 193px;
                        }
                        &:nth-child(5) {
                            width: 190px;
                        }
                        &:nth-child(6) {
                            width: 10%;
                        }
                        &:nth-child(7) {
                            width: 3.5%;
                        }
                    }
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 1px 10px;

                &.DISAPPROVED {
                    background-color: #db6968;
                    color: white;
                }

                &.PENDING {
                    background-color: #f2c466;
                }

                &.APPROVED {
                    background-color: #6acff1;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
